/* eslint-disable */
import {Vue} from 'vue-class-component';
import {Getter, Mutation, Action, State} from 'vuex-class';
import {ModelAccountState} from '@/store/modelAccount/types';
import {modelAccount} from '@/store/modelAccount';
import {OrderPayment} from '@/components/pages/model-account/my-account-order-payout/model-account-my-account-order-payment.interface';
import {CustomerState} from "@/store/customer/types";

const namespace: string = 'modelAccount';


export default class ModelAccountMyAccountOrderPaymentPage extends Vue {
	@State('modelAccount') modelAccount: ModelAccountState | undefined;
	@Action('setOrderPayment', {namespace}) setOrderPayment: any;
	@State('customer') customer: CustomerState | undefined;

	$refs!: {
		refPayment: HTMLInputElement
	};

	orderPayment: OrderPayment = {
		method: '',
		amount: 0
	};

	handleClickElement(e: Event) {
		const {target} = e;
		if ((target as HTMLLIElement).nodeName === 'LI') {
			this.$refs.refPayment.value = (target as HTMLLIElement).innerText;
			this.orderPayment.method = (target as HTMLLIElement).innerText;
		}
	}

	changeSelected() {
		document.addEventListener('click', this.handleClickElement);
	}

	onOrder() {
		this.setOrderPayment(this.orderPayment);
		this.orderPayment = {
			method: '',
			amount: 0
		};
		document.removeEventListener('click', this.handleClickElement);
	}
}
